import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AboutSection = () => {
  const navigate = useNavigate();

  const navigateToTransform = () => {
    navigate('/transform'); // The path you want to navigate to
  };

  const navigateToSwap = () => {
    navigate('/swap'); // The path you want to navigate to
  };

  const navigateToClotheSwap = () => {
    navigate('/clothes'); // The path you want to navigate to
  };

  return (

    <div id='about' className='about-section'>
    <div className='container'>
      <div className='row'>
        <div className='col-xs-12 col-md-6'>
          <img src='/imgs/ex1.jpg' onClick={navigateToTransform} className='img-responsive about-img' alt='About Us' />
        </div>
        <div className='col-xs-12 col-md-6'>
          <div className='about-text'>
            <h2>How to GetMyst</h2>
            <p>Write in the character description, upload your image, and hit generate. Our intuitive platform allows you to become anyone or anything you imagine, just by uploading a photo.</p>
            <h3>Why Choose GetMyst?</h3>
            <div className='list-style'>
              <ul>
                <li>Innovative AI technology</li>
                <li>User-friendly interface</li>
              </ul>
              <ul>
                <li>High-quality image transformations</li>
                <li>Responsive design for all devices</li>
              </ul>
            </div>
            <Button variant="primary" onClick={navigateToTransform}>Try Face Transform</Button>
          </div>
        </div>
      </div>
      <br>
      </br>
      <br>
      </br>
      <div className='row separator'>
        <div className='col-xs-12 col-md-6'>
          <div className='about-text'>
            <h2>How to Face Swap</h2>
            <p>Create astounding face swaps by providing two photographs. Our sophisticated platform enables our users to swap faces in their images conveniently.</p>
            <div className='list-style'>
              <ul>
                <li>Proprietary AI technology</li>
                <li>Simple interface</li>
              </ul>
              <ul>
                <li>High-quality FaceSwap</li>
                <li>Low wait times</li>
              </ul>
            </div>
            <Button variant="primary" onClick={navigateToSwap}>Try Face Swap</Button>
          </div>
        </div>
        <div className='col-xs-12 col-md-6'>
          <img src='/imgs/ex2.jpg' onClick={navigateToSwap} className='img-responsive about-img' alt='faceswap-example' />
        </div>
      </div>
      <br>
      </br>
      <br>
      </br>
      <div className='row separator'>
        <div className='col-xs-12 col-md-6'>
          <img src='/imgs/ex3.jpg' onClick={navigateToClotheSwap} className='img-responsive about-img' alt='ClotheSwap-example' />
        </div>
        <div className='col-xs-12 col-md-6'>
          <div className='about-text'>
            <h2>Clothes Swap made easy</h2>
            <p>Create intriguing fashion statements by swapping clothes between images. Provide one image for the clothing style and one image of a person and let our platform do the rest.</p>
            <h3>Features:</h3>
            <div className='list-style'>
              <ul>
                <li>Integration with big brands</li>
                <li>Instant 15 seconds result</li>
              </ul>
            </div>
            <Button variant="primary" onClick={navigateToClotheSwap}>Try Clothes Swap</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}


export default AboutSection;
