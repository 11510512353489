import React from 'react';
import ImageUploader from './components/ImageUploader';
import SEO from './SEO';

function ImageUploaderPage() {
  return (
    <div>
      <SEO 
        title="Face Transform using AI | GetMyst"
        description="Unleash creativity with face transformations! Age, gender swap, and more - explore endless possibilities. Make your photos extraordinary with GetMyst.app."
        name="GetMyst.app"
        type="website" 
        url="https://getmyst.app/transform"
      />

      <div className="container mt-5">
        <h1 className="text-center mb-4">Transform Your Photos with AI</h1>
        <p className="text-center mb-5">
          Upload a photo and let our AI transform it into amazing character variations. 
          Perfect for creative projects, social media, or just having fun!
        </p>

        <ImageUploader />
      </div>
    </div>
  );
}

export default ImageUploaderPage;