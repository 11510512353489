import { CognitoUserPool } from 'amazon-cognito-identity-js';

/*
const poolData = {
  UserPoolId: 'us-east-1_UYEyHP5EH',
  ClientId: '7ehv243c2d25s4t5pl5nsnpitf',
};*/

const poolData = {
  UserPoolId: 'us-east-1_zAx3vOoXR',
  ClientId: '21499i82te3e095n6rv3b8kij3',
}

export default new CognitoUserPool(poolData);