import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './AppNavbar.css';
import { useContext, useEffect, useState } from 'react';
import { AccountContext } from './Account';

function AppNavbar() {
    const [status, setStatus] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const { getSession, logout, isSubscribed } = useContext(AccountContext);
  
    useEffect(() => {
      getSession()
        .then(async (session) => {
          setStatus(true);
          const subStatus = await isSubscribed();
          setSubscribed(subStatus);
        })
        .catch((err) => {
          setStatus(false);
          setSubscribed(false);
        });
    }, [status]);

    return (
        <Navbar className="navbar-custom" expand="lg">
            <Container>
                <Navbar.Brand href="/">GetMyst</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/blog">Blog</Nav.Link>
                        <Nav.Link href="/swap">Face Swap</Nav.Link>
                        <Nav.Link href="/transform">Face Transform</Nav.Link>
                        <Nav.Link href="/clothes">Try Clothes</Nav.Link>
                        <Nav.Link href="/contact">Contact</Nav.Link>
                        {status ? (
                            <>
                            {subscribed ? (
                                <Nav.Link href="https://billing.stripe.com/p/login/5kAfZzg0y7Zy0b6aEE">
                                    Manage Subscription
                                </Nav.Link>
                        ) : (
                                <Nav.Link href="/subscription-1">Subscribe</Nav.Link>
                            )}
                            <Nav.Link href="/profile">Profile</Nav.Link>
                            <Nav.Link onClick={logout}>Logout</Nav.Link>
                            </>
                        ) : (
                            <>
                            <Nav.Link href="/login">Login</Nav.Link>
                            <Nav.Link href="/register">Register</Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AppNavbar;
