import React, { useState, useContext } from 'react';
import ReactImageFileResizer from 'react-image-file-resizer';
import { useNavigate } from "react-router-dom";
import { Spinner, Modal, Button, Row, Col, FormCheck, Tooltip, Form,
    OverlayTrigger  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { AccountContext } from '../Account';
import DisplayImage from '../DisplayImage';
import useTrial from '../UserTracker';
import { getSignedUrl, processImage, recordFeedbackSubmit, checkQueueStatus } from '../api';


const resizeImage = (file) => {
    return new Promise((resolve) => {
      ReactImageFileResizer.imageFileResizer(
        file,
        768, // maxWidth
        1024, // maxHeight
        'PNG', // compressFormat
        100, // quality
        0, // rotation
        (uri) => {
          resolve(uri);
        },
        'file' // outputType
      );
    });
};

function ImageUploader() {
    const [imagePreview, setImagePreview] = useState(null);
    const [imageFile, setImageFile] = useState(null); // Store the uploaded file
    const [outputImage, setOutputImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState('');
    const [characterName, setCharacterName] = useState('');
    const [showGenerateButton, setShowGenerateButton] = useState(true);
    const { getToken } = useContext(AccountContext); 
    const { incrementTrialCount, isTrialExpired } = useTrial();
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [gender, setGender] = useState(''); 
    const [feedback, setFeedback] = useState('');
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [isRealistic, setIsRealistic] = useState(true);

    const renderTooltip = (props, message) => (
        <Tooltip id="button-tooltip" {...props}>
            {message}
        </Tooltip>
    );
    const navigate = useNavigate();

    const handleRegister = () => navigate('/register');
    const handleClose = () => setShowModal(false);
    const handleShow = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    const handleLikeDislike = async (like) => {
        //console.log(like)
        if(like) {
            // Record like action immediately
            await recordFeedback(true, '');
        } else {
            // Show feedback modal for dislike
            setShowFeedbackModal(true);
        }
    };

    const recordFeedback = async (like, comment) => {
        const userID = ''; // Set user ID if available
        const feedbackData = {
            userID,
            fileKey: imageFile?.name || '',
            characterDescription: characterName,
            gender,
            like,
            comment
        };
        
        // Send feedbackData to your API endpoint for storing in DynamoDB
        try {
            const token = await getToken();
            await recordFeedbackSubmit(feedbackData, token);
            //console.log('Feedback recorded:', response.data);
        } catch (error) {
            //console.error('Error recording feedback:', error);
        }

        setShowFeedbackModal(false);
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        try {
            const resizedImage = await resizeImage(file);
            setImageFile(resizedImage);
            
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(resizedImage);
            
            if (!characterName) {
                setShowGenerateButton(true); // Show the button if no character name is entered
                return; // Stop further execution
            }
            
            // Now we use the resizedImage directly instead of imageFile state
            await startImageProcessing(resizedImage);
            
        } catch (error) {
            console.error('Error resizing image:', error);
        }
    };

    const startImageProcessing = async (file) => {
        const fileName = file.name;
        const contentType = file.type;
        const estimatedSize = file.size;
        const token = await getToken();

        if(!token && isTrialExpired())
        {
            handleShow("Please Register to get more credits! (IT'S STILL FREE)")
        }
        else
        {
            setLoading(true);
            setLoadingStatus('Strapping your image into the creativity capsule..');
            // Request a signed URL from your backend
            const { url: signedUrl, filename: file_key } = await getSignedUrl(
                fileName, contentType, estimatedSize, token
            );

            // Notify backend about the uploaded image
            try
            {
                await processImage(signedUrl, file, file_key, characterName, gender, isRealistic, token);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 Unauthorized error
                    handleShow('Session expired. Please sign in again.');
                    navigate('/login'); // Redirect to login page
                } else if (error.response && error.response.status === 402) {
                    // Handle 402 Payment Required error
                    handleShow('Ran out of free credits, subscribe or wait 24 hrs');
                } else {
                    // Handle other errors
                    handleShow('An error occurred: Please Login to Get More Credits');
                }
                setLoading(false);
                return;
            }

            const maxWaitTime = 120000; // 2 minutes in milliseconds
            let elapsedTime = 0;
            const checkInterval = 5000; // 5 seconds
            const uuid1 = file_key.split('/').pop();
            const quirkyMessages = [
                "Hang tight, we're painting your masterpiece!",
                "Still cooking! Art takes time, you know.",
                "Hold on, we're adding the finishing touches!",
                "Magic in progress... keep watching!",
                "Artists at work... your patience is appreciated!",
                // Add more messages as you like
            ];
            let messageIndex = 0;

            // Start checking the queue status periodically
            const checkStatusInterval = setInterval(async () => {
                try {
                    const queueStatusResponse = await checkQueueStatus(uuid1, token);
                    const queueStatus = queueStatusResponse.data.status;

                    if (queueStatus === 'COMPLETED') {
                        clearInterval(checkStatusInterval);
                        const outputMessage = queueStatusResponse.data.output.message;
                        setLoading(false);
                        setLoadingStatus('');
                        setOutputImage(`data:image/png;base64,${outputMessage}`);
                        incrementTrialCount();
                    } else if (queueStatus === 'FAILED' || queueStatus === 'CANCELLED' || queueStatus === 'TIMED_OUT') {
                        clearInterval(checkStatusInterval);
                        setLoading(false);
                        setLoadingStatus('Processing Failed');
                        handleShow('Processing failed. Please try again.');
                    } else {
                        setLoadingStatus(quirkyMessages[messageIndex % quirkyMessages.length]);
                        messageIndex++;
                    }
                } catch (error) {
                    console.error('Error checking status:', error);
                    // Optional: Update the user interface to show an error message
                    // For instance: setLoadingStatus('Encountered a temporary issue, still checking the status...');
                }

                elapsedTime += checkInterval;
                if (elapsedTime >= maxWaitTime) {
                    clearInterval(checkStatusInterval);
                    setLoading(false);
                    setLoadingStatus('');
                    handleShow('The image is still processing. Please check back later in your profile page.');
                }
            }, checkInterval); // Check every 5 seconds
            setShowGenerateButton(true);
        }

    };



    return (
        <div>
            <div className="container mt-4">
                {loading && (
                        <div className="full-screen-overlay" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: 'rgba(0, 0, 0, 0.7)', height: '100vh', width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1050 }}>
                            <Spinner animation="border" role="status" style={{ width: '4rem', height: '4rem' }}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <p className="text-white" style={{ marginTop: '20px', fontSize: '1.5rem' }}>{loadingStatus}</p>
                        </div>
                    )}
                <Row className="justify-content-between mb-3">
                        <Col md={6}>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props) => renderTooltip(props, "Enter a name or a description for the character. Be creative!")}
                            >
                                <div className="mb-3">
                                    <label htmlFor="characterName" className="form-label">Character Name/Description</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="characterName"
                                        value={characterName}
                                        onChange={(e) => setCharacterName(e.target.value)}
                                        placeholder="example: Elf Warrior, Futuristic Astronaut"
                                    />
                                </div>
                            </OverlayTrigger>

                            {/* Gender Radios */}
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props) => renderTooltip(props, "Select the gender for the character transformation")}
                            >
                            <div >
                                <label className="form-label d-block">Gender:</label>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="genderOptions"
                                        id="genderMasculine"
                                        value="Masculine"
                                        checked={gender === 'Masculine'}
                                        onChange={(e) => setGender(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="genderMasculine">
                                        Masculine
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="genderOptions"
                                        id="genderFeminine"
                                        value="Feminine"
                                        checked={gender === 'Feminine'}
                                        onChange={(e) => setGender(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="genderFeminine">
                                        Feminine
                                    </label>
                                </div>
                            </div>
                            </OverlayTrigger>
                            <hr style={{ borderStyle: 'dotted' }} />
                            {/* Realistic Checkbox */}
                            <Form.Group className="mb-3">
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props) => renderTooltip(props, "Uncheck for anime style transformations")}
                                >
                                    <FormCheck
                                        type="checkbox"
                                        id="realisticCheckbox"
                                        label="Realistic"
                                        checked={isRealistic}
                                        onChange={(e) => setIsRealistic(e.target.checked)}
                                        // custom removed
                                    />
                                </OverlayTrigger>
                            </Form.Group>
                            <hr style={{ borderStyle: 'dotted' }} />
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props) => renderTooltip(props, "Upload a clear image of a face for the best results. Avoid full body pictures.")}
                            >
                                <div className="mb-3">
                                    <label className="form-label">
                                        Upload Image of a Face
                                    </label>
                                    <input
                                        type="file"
                                        onChange={handleImageChange}
                                        className="form-control"
                                        aria-describedby="imageUploadHelp"
                                    />
                                    <small id="imageUploadHelp" className="form-text text-muted">
                                        Please upload a clear image of a face for optimal transformation.
                                    </small>
                                </div>
                            </OverlayTrigger>
                            {imagePreview && (
                                <img src={imagePreview} alt="Input" className="img-thumbnail mt-3" style={{ width: '100%', height: 'auto' }} />
                            )}
                        </Col>
                        <Col md={6}>
                            <div>
                                <Row className="align-items-center">
                                    <Col>
                                        <label className="form-label">Transformed Image</label>
                                    </Col>
                                    <Col>
                                        {showGenerateButton && (
                                            <Button variant="primary" onClick={() => startImageProcessing(imageFile)}>
                                                Generate
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                {outputImage ? (
                                    <div>
                                        <DisplayImage src={outputImage} />
                                        <div className="feedback-buttons mt-3 d-flex justify-content-center">
                                            <Row>
                                                <Col>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    className="text-success mr-2 icon-animate"
                                                    style={{ cursor: 'pointer', fontSize: '2rem' }}
                                                    onClick={() => handleLikeDislike(true)}
                                                />
                                                </Col>
                                                <Col>
                                                <FontAwesomeIcon
                                                    icon={faThumbsDown}
                                                    className="text-danger ml-2 icon-animate"
                                                    style={{ cursor: 'pointer', fontSize: '2rem' }}
                                                    onClick={() => handleLikeDislike(false)}
                                                />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Modal show={showFeedbackModal} onHide={() => setShowFeedbackModal(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Feedback</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <textarea
                                                    className="form-control"
                                                    rows="3"
                                                    placeholder="Your feedback... (Optional)"
                                                    value={feedback}
                                                    onChange={(e) => setFeedback(e.target.value)}
                                                ></textarea>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowFeedbackModal(false)}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" onClick={() => recordFeedback(false, feedback)}>
                                                    Submit
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                ) : (
                                    <div className="img-thumbnail mt-3" style={{ width: '100%', height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span style={{ color: '#6c757d' }}>No image to display</span>
                                    </div>
                                )}
                            </div>
                        </Col>

                </Row>

                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleRegister}>
                            Register
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );

}

export default ImageUploader;
