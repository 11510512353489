import React from 'react';
import ClothesSwapUploader from './components/ClothesSwapUploader';
import SEO from './SEO';

function ClothesSwapPage() {
  return (
    <div>
      <SEO 
        title="Clothes Swap using AI | GetMyst"
        description="Revamp your style using AI! Swap outfits in photos to preview fashion choices effortlessly. Explore new looks with GetMyst.app's Clothes Swap."
        name="GetMyst.app"
        type="website"
        url="https://getmyst.app/clothes"
      />

      <div className="container mt-5">
        <h1 className="text-center mb-4">AI Clothes Swap</h1>
        <p className="text-center mb-5">
          Upload a photo of clothes and a person to see how they would look wearing that outfit. 
          Perfect for virtually trying on clothes and exploring different styles!
        </p>

        <ClothesSwapUploader />
      </div>
    </div>
  );
}

export default ClothesSwapPage;