import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import AppNavbar from './AppNavbar';
import Login from './Login';
import Register from './Register';
import Profile from './Profile';
import { Account } from './Account';
import ForgotPassword from './ForgotPassword';
import ContactPage from './ContactPage';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './tos';
import { HelmetProvider } from 'react-helmet-async';
import Subscription1 from './SubscriptionProcess';
import SuccessPayment from './SuccessPayment';
import CancelPayment from './CancelPayment';
import BlogPage from './BlogPage';
import BlogPost from './BlogPost';
import ImageUploaderPage from './ImageUploaderPage';
import FaceSwapPage from './FaceSwapPage';
import ClothesSwapPage from './ClothesSwapPage';

function App() {
  return (
    <HelmetProvider>
      <Account>
        <Router>
          <div>
            <AppNavbar />
            <Routes>
            <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/tos" element={<TermsOfService />} />
              <Route path="/swap" element={<FaceSwapPage />} />
              <Route path="/transform" element={<ImageUploaderPage />} />
              <Route path="/clothes" element={<ClothesSwapPage/>} />
              <Route path="/subscription-1" element={<Subscription1 />} />
              <Route path="/success" element={<SuccessPayment />} />
              <Route path="/cancel" element={<CancelPayment />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              {/* Add more routes as needed */}
            </Routes>
          </div>
        </Router>
      </Account>
    </HelmetProvider>
  );
}

export default App;