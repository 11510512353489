import axios from 'axios';

const API_BASE_URL = 'https://n1qqit84dj.execute-api.us-east-1.amazonaws.com/prod';
//const API_BASE_URL = 'https://0lrqarhmx7.execute-api.us-east-1.amazonaws.com/stage';

export async function getSignedUrl(fileName, contentType, estimatedSize, token) {
    const response = await axios.get(`${API_BASE_URL}/get-signed-url`, {
        params: { fileName, contentType, estimatedSize },
        headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
}

export async function processImage(signedUrl, file, fileKey, characterName, gender, isRealistic, token) {
    // Upload to S3
    await axios.put(signedUrl, file, { headers: { 'Content-Type': file.type } });

    // Notify backend about the uploaded image
    const endpoint = token ? '/process-image' : '/public-process-image';
    const payload = {
        imageUrl: signedUrl, 
        file_key: fileKey,
        character_name: characterName,
        gender,
        realistic: isRealistic,
    };
    await axios.post(`${API_BASE_URL}${endpoint}`, payload, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
}

// Function to record user feedback
export async function recordFeedbackSubmit(feedbackData, token) {
    const endpoint = token ? '/collect-feedback-private' : '/collect-feedback';
    await axios.post(`${API_BASE_URL}${endpoint}`, feedbackData, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
}

// Function to check the status of the image processing queue
export async function checkQueueStatus(filename, token, is_faceswap=false, is_clotheswap=false) {
    if(is_faceswap || is_clotheswap) {
        const response = await axios.get(`${API_BASE_URL}/queue_status`, {
            params: { filename, is_faceswap, is_clotheswap },
            headers: { 'Authorization': `Bearer ${token}` },
        });
        return response;
    }
    else {
        const response = await axios.get(`${API_BASE_URL}/queue_status`, {
            params: { filename },
            headers: { 'Authorization': `Bearer ${token}` },
        });
        return response;
    }   

}

export async function getSignedUrlsForFaceSwap(sourceFileName, sourceContentType, targetFileName, targetContentType, token) {
    // Assuming your API expects a GET request with these query parameters
    const response = await axios.get(`${API_BASE_URL}/get-signed-url`, {
        params: {
            fileName: sourceFileName,
            contentType: sourceContentType,
            targetType: targetContentType,
            purpose: 'faceswap'
        },
        headers: { 'Authorization': `Bearer ${token}` },
    });

    return response.data;
}

export async function processFaceSwapImage(sourceUrl, sourceFile, targetUrl, targetFile,
    sourceFileKey, targetFileKey, token, gender) {
    // Upload source image to S3
    await axios.put(sourceUrl, sourceFile, { headers: { 'Content-Type': sourceFile.type } });
    
    // Upload target image to S3
    await axios.put(targetUrl, targetFile, { headers: { 'Content-Type': targetFile.type } });

    // Notify backend to process face swap
    const payload = {
        sourceImageUrl: sourceUrl, 
        targetImageUrl: targetUrl,
        source_file_key: sourceFileKey,
        target_file_key: targetFileKey,
        gender: gender
    };
    //console.log(payload)
    const endpoint = token ? '/process-faceswap' : '/public-process-faceswap';

    await axios.post(`${API_BASE_URL}${endpoint}`, payload, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
}

export async function getSignedUrlsForClothesSwap(sourceFileName, sourceContentType, targetFileName, targetContentType, token) {
    // Assuming your API expects a GET request with these query parameters
    const response = await axios.get(`${API_BASE_URL}/get-signed-url`, {
        params: {
            fileName: sourceFileName,
            contentType: sourceContentType,
            targetType: targetContentType,
            purpose: 'clotheswap'
        },
        headers: { 'Authorization': `Bearer ${token}` },
    });

    return response.data;
}

export async function processClothesSwapImage(sourceUrl, sourceFile, targetUrl, targetFile,
    sourceFileKey, targetFileKey, token, gender) {
    // Upload source image to S3
    await axios.put(sourceUrl, sourceFile, { headers: { 'Content-Type': sourceFile.type } });
    
    // Upload target image to S3
    await axios.put(targetUrl, targetFile, { headers: { 'Content-Type': targetFile.type } });

    // Notify backend to process face swap
    const payload = {
        sourceImageUrl: sourceUrl, 
        targetImageUrl: targetUrl,
        source_file_key: sourceFileKey,
        target_file_key: targetFileKey,
        gender: gender
    };
    //console.log(payload)
    const endpoint = token ? '/process-clotheswap' : '/public-process-clotheswap';

    await axios.post(`${API_BASE_URL}${endpoint}`, payload, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
}

export async function initiateSubscription() {
    const response = await axios.post(`${API_BASE_URL}/pay`, {
        line_items: [
            {
                price: "price_1QSB33JVYOfaniuir8u448q6",
                quantity: 1,
            },
        ],
    }, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response.data;
}

export async function checkSubscriptionStatus(token) {
    const response = await axios.get(`${API_BASE_URL}/subscription-status`, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
}