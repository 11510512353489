import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faShareAlt } from '@fortawesome/free-solid-svg-icons';

const DisplayImage = ({ src }) => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = src;
        link.download = 'transformed-image.png'; // Dynamic filename can be set here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Check out this image',
                url: src, // URL of the image to share
            }).then(() => {
                // console.log('Thanks for sharing!');
            })
            .catch(console.error);
        } else {
            // Fallback for browsers that don't support the Web Share API
            alert('Your browser does not support the share feature. Please manually share the URL.');
        }
    };

    return (
        <div className="output-image-container" style={{ position: 'relative', display: 'inline-block' }}>
            <img src={src} alt="Output" className="img-thumbnail" style={{ width: '100%', height: 'auto' }} />
            <div className="image-overlay" style={{ 
                position: 'absolute', 
                top: 10, 
                right: 10, 
                display: 'flex',
                borderRadius: '4px' // Rounded corners
            }}>
                <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', margin: '0 5px', borderRadius: '4px' }}>
                    <FontAwesomeIcon icon={faDownload} onClick={handleDownload} style={{ cursor: 'pointer', fontSize: '20px', color: '#000' }} />
                </div>
                <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '4px' }}>
                    <FontAwesomeIcon icon={faShareAlt} onClick={handleShare} style={{ cursor: 'pointer', fontSize: '20px', color: '#000' }} />
                </div>
            </div>
        </div>
    );
};


export default DisplayImage; 
