import React from 'react';
import { Card, Button, Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMoneyBill, faBuilding, faImage, faUser, faShieldAlt, faServer  } from '@fortawesome/free-solid-svg-icons';


const PricingCard = ({ title, titleIcon, price, features, buttonText, buttonVariant, buttonHref, featureIcons }) => (
    <Card className="text-center">
        {titleIcon && (
            <FontAwesomeIcon 
                icon={titleIcon} 
                className="mb-2" 
                style={{ fontSize: '32px' }} // Increase the font size
            />
        )}
        <Card.Header as="h5">{title}</Card.Header>
        <Card.Body>
            <Card.Title>{price}</Card.Title>
            <div> {/* Changed from Card.Text to div */}
                {features.map((feature, index) => (
                    <div key={index}>
                        <FontAwesomeIcon icon={featureIcons[index]} /> {feature}
                    </div>
                ))}
            </div>
            <Button variant={buttonVariant} href={buttonHref}>{buttonText}</Button>
        </Card.Body>
    </Card>
);

const HomePricingSection = () => {
  const plans = [
    {
      title: "Free",
      titleIcon: faCheckCircle,
      price: "Free",
      features: ["3 Image Generations/ Day", "Basic Features", "Community Support", "Lowest Priority"],
      buttonText: "Get Started",
      featureIcons: [faImage, faUser, faShieldAlt, faServer],
      buttonVariant: "primary",
      buttonHref: "#"
    },
    {
      title: "Paid",
      titleIcon: faMoneyBill,
      price: "$4.99 / Month",
      featureIcons: [faImage, faUser, faShieldAlt, faServer],
      features: ["High Priority Generation", "Advanced Features", "Priority Support", "Remove Watermark"],
      buttonText: "Subscribe",
      buttonVariant: "success",
      buttonHref: "/profile"
    },
    {
      title: "Enterprise",
      titleIcon: faBuilding,
      featureIcons: [faImage, faUser, faShieldAlt, faServer],
      price: "Contact Us",
      features: ["Custom Solutions", "Dedicated Support", "Enterprise-grade Security", "Private Instance"],
      buttonText: "Learn More",
      buttonVariant: "warning",
      buttonHref: "/contact"
    },
  ];

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Pricing Plans</h2>
      <Row xs={1} md={3} className="g-4">
        {plans.map((plan, idx) => (
          <Col key={idx}>
            <PricingCard {...plan} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default HomePricingSection;
