import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const CancelPayment = () => {
  const handleHomeRedirect = () => {
    window.location.href = '/';  // Reloads the home page
  };

  return (
    <Container className="text-center mt-4">
      <FontAwesomeIcon 
        icon={faCircleXmark} 
        style={{ color: 'red', fontSize: '100px' }} 
      />
      <h2 className="my-4">Payment Canceled</h2>
      <Button variant="primary" onClick={handleHomeRedirect}>
        Home
      </Button>
    </Container>
  );
};

export default CancelPayment;