import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useState } from 'react';
import UserPool from './UserPool';
import { useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col, Modal } from 'react-bootstrap';
import SEO from './SEO';

function Register() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyProcess, setVerifyProcess] = useState(false);
    const [OTP, setOTP] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptedTos, setAcceptedTos] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => setShowModal(false);
    const handleShow = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!acceptedTos) {
            handleShow("You must accept the Terms of Service to register");
            return;
        }
        if (password !== confirmPassword) {
            handleShow("Passwords do not match");
            return;
        }
        const attributeList = [];
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            })
        );
        UserPool.signUp(username, password, attributeList, null, (err, data) => {
        if (err) {
            handleShow("Couldn't sign up");
        } else {
            setVerifyProcess(true);
            handleShow('Registered! Check your Email for OTP');
        }
        });
    };

    const verifyAccount = (e) => {
        e.preventDefault();
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });
        user.confirmRegistration(OTP, true, (err, data) => {
        if (err) {
            handleShow("Couldn't verify account: " + err);
        } else {
            handleShow('Account verified successfully');
            navigate('/login');
        }
        });
    };

    return (
        <div>
            <SEO title="Sign Up | GetMyst"
            description="Create your GetMyst.app account today. Join the community and start transforming your photos with ease."
            name="GetMyst.app"
            type="website" 
            url="https://getmyst.app/register"
            />
            <Container className="mt-5">
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                    <h1 className="text-center mb-4">Register</h1>
                        {verifyProcess === false ? (
                            <Form onSubmit={onSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            setUsername(e.target.value);
                                        }}
                                        placeholder="Enter email"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password:</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Password"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                                    <Form.Label>Confirm Password:</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm Password"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formAcceptTos">
                                    <Form.Check
                                        type="checkbox"
                                        label={<span>I accept the <a href="/tos" target="_blank">Terms of Service</a></span>}
                                        checked={acceptedTos}
                                        onChange={(e) => setAcceptedTos(e.target.checked)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">Register</Button>
                            </Form>
                        ) : (
                            <Form onSubmit={verifyAccount}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Enter the OTP to Verify your email:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={OTP}
                                        onChange={(e) => setOTP(e.target.value)}
                                        placeholder="OTP"
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">Verify</Button>
                            </Form>
                        )}
                        <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Message</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{modalMessage}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Register;