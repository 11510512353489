import React, { useState, useContext } from 'react';
import ReactImageFileResizer from 'react-image-file-resizer';
import { Spinner, Modal, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { AccountContext } from '../Account';
import useTrial from '../UserTracker';
import { getSignedUrlsForFaceSwap, processFaceSwapImage, checkQueueStatus, recordFeedbackSubmit } from '../api';
import DisplayImage from '../DisplayImage';
import ReactCompareImage from "react-compare-image";

const resizeImage = (file) => {
    return new Promise((resolve) => {
      ReactImageFileResizer.imageFileResizer(
        file,
        768, // maxWidth
        1024, // maxHeight
        'PNG', // compressFormat
        100, // quality
        0, // rotation
        (uri) => {
          resolve(uri);
        },
        'file' // outputType
      );
    });
};

function FaceSwapUploader() {
    const [sourceImagePreview, setSourceImagePreview] = useState(null);
    const [targetImagePreview, setTargetImagePreview] = useState(null);
    const [sourceImageFile, setSourceImageFile] = useState(null);
    const [targetImageFile, setTargetImageFile] = useState(null);
    const [outputImage, setOutputImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState('');
    const [gender, setGender] = useState('Feminine');
    const { getToken } = useContext(AccountContext); 
    const { incrementTrialCount, isTrialExpired } = useTrial();
    const [feedback, setFeedback] = useState('');
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const renderTooltip = (props, message) => (
        <Tooltip id="button-tooltip" {...props}>
            {message}
        </Tooltip>
    );

    const handleLikeDislike = async (like) => {
        if (like) {
            await recordFeedback(true, '');
        } else {
            setShowFeedbackModal(true);
        }
    };

    const recordFeedback = async (like, comment) => {
        const userID = ''; // Set user ID if available
        const feedbackData = {
            userID,
            fileKey: sourceImageFile?.name || '',
            characterDescription: 'faceswap',
            gender,
            like,
            comment
        };
        
        // Send feedbackData to your API endpoint for storing in DynamoDB
        try {
            const token = await getToken();
            await recordFeedbackSubmit(feedbackData, token);
            //console.log('Feedback recorded:', response.data);
        } catch (error) {
            //console.error('Error recording feedback:', error);
        }

        setShowFeedbackModal(false);
    };

    const handleImageChange = async (e, setImageFile, setImagePreview) => {
        const file = e.target.files[0];
        try 
        {
            const resizedImage = await resizeImage(file);
            setImageFile(resizedImage);
            
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(resizedImage);
            
        } catch (error) {
            console.error('Error resizing image:', error);
          }

    };

    const startFaceSwapProcessing = async () => {
        const token = await getToken();
        if (!token && isTrialExpired()) {
            handleShow("Please sign in to continue. (IT'S STILL FREE)");
            return;
        }

        setLoading(true);
        setLoadingStatus('Preparing images for face swap...');
        let file_key = '';
        try {
            const s3SignedResponse = await getSignedUrlsForFaceSwap(
                sourceImageFile.name, sourceImageFile.type,
                targetImageFile.name, targetImageFile.type,
                token
            );

            const sourceUrl = s3SignedResponse.source_url
            const targetUrl = s3SignedResponse.target_url
            const source_file_key = s3SignedResponse.source_filename
            const target_file_key = s3SignedResponse.target_filename
            await processFaceSwapImage(sourceUrl, sourceImageFile, targetUrl, targetImageFile, 
                source_file_key, target_file_key, token, gender);
            
            file_key = source_file_key;

        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleShow('Session expired. Please sign in again.');
            } else if (error.response && error.response.status === 402) {
                handleShow('Ran out of free usage, subscribe or wait 24 hrs');
            } else {
                console.error('Error during face swap processing:', error);
                handleShow('An error occurred during face swap processing.');
            }
            setLoading(false);
            return;
        }

        const maxWaitTime = 120000; // 2 minutes in milliseconds
        let elapsedTime = 0;
        const checkInterval = 5000; // 5 seconds
        const uuid1 = file_key.split('/').pop();
        const quirkyMessages = [
            "Hang tight, we're painting your masterpiece!",
            "Still cooking! Art takes time, you know.",
            "Hold on, we're adding the finishing touches!",
            "Magic in progress... keep watching!",
            "Artists at work... your patience is appreciated!",
            // Add more messages as you like
        ];
        let messageIndex = 0;

        // Start checking the queue status periodically
        const checkStatusInterval = setInterval(async () => {
            try {
                const queueStatusResponse = await checkQueueStatus(uuid1, token, true);
                const queueStatus = queueStatusResponse.data.status;
        
                if (queueStatus === 'COMPLETED') {
                    clearInterval(checkStatusInterval);
                    const outputMessage = queueStatusResponse.data.output.message;
                    setLoading(false);
                    setLoadingStatus('');
                    setOutputImage(`data:image/png;base64,${outputMessage}`);
                    incrementTrialCount();
                } else if (queueStatus === 'FAILED' || queueStatus === 'CANCELLED' || queueStatus === 'TIMED_OUT') {
                    clearInterval(checkStatusInterval);
                    setLoading(false);
                    setLoadingStatus('Processing Failed');
                    handleShow('Processing failed. Please try again.');
                } else {
                    setLoadingStatus(quirkyMessages[messageIndex % quirkyMessages.length]);
                    messageIndex++;
                }
            } catch (error) {
                console.error('Error checking status:', error);
                // Optional: Update the user interface to show an error message
                // For instance: setLoadingStatus('Encountered a temporary issue, still checking the status...');
            }
        
            elapsedTime += checkInterval;
            if (elapsedTime >= maxWaitTime) {
                clearInterval(checkStatusInterval);
                setLoading(false);
                setLoadingStatus('');
                handleShow('The image is still processing. Please check back later in your profile page.');
            }
        }, checkInterval); // Check every 5 seconds



    };

    const handleShow = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <div>
            <div className="container mt-4">
                {loading && (
                    <div className="full-screen-overlay" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: 'rgba(0, 0, 0, 0.7)', height: '100vh', width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1050 }}>
                        <Spinner animation="border" role="status" style={{ width: '4rem', height: '4rem' }}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <p className="text-white" style={{ marginTop: '20px', fontSize: '1.5rem' }}>{loadingStatus}</p>
                    </div>
                )}
                <Row>
                    {/* Source Image Upload with Tooltip */}
                    <Col md={6}>
                        {/* Upload Section */}
                        <Row>
                            {/* Source Image Upload */}
                            <Col md={6} className="text-center">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props) => renderTooltip(props, "Upload the face image to be swapped.")}
                                >
                                    <div className="mb-3">
                                        <label>Upload Source Image</label>
                                        <input type="file" className="form-control" onChange={(e) => handleImageChange(e, setSourceImageFile, setSourceImagePreview)} />
                                    </div>
                                </OverlayTrigger>
                            </Col>

                            {/* Target Image Upload */}
                            <Col md={6} className="text-center">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props) => renderTooltip(props, "Upload the target image for the face swap.")}
                                >
                                    <div className="mb-3">
                                        <label>Upload Target Image</label>
                                        <input type="file" className="form-control" onChange={(e) => handleImageChange(e, setTargetImageFile, setTargetImagePreview)} />
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    <Row>
                        {/* Source Image Preview */}
                        <Col className="text-center">
                            {sourceImagePreview ? (
                                <img src={sourceImagePreview} alt="Source" style={{ maxWidth: '100%', height: 'auto' }} />
                            ) : (
                                <label className="img-thumbnail mt-3" style={{ width: '100%', height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                                    <span style={{ color: '#6c757d' }}>
                                        <img src='/imgs/face.png' className='img-responsive about-img' alt='Face' style={{ maxWidth: '30%', filter: 'grayscale(100%)' }} />
                                        <input type="file" className="form-control" style={{ display: 'none' }} onChange={(e) => handleImageChange(e, setSourceImageFile, setSourceImagePreview)} />
                                    </span>
                                </label>
                            )}
                        </Col>

                            {/* Target Image Preview */}
                            <Col className="text-center">
                                {targetImagePreview ? (
                                    <img src={targetImagePreview} alt="Target" style={{ maxWidth: '100%', height: 'auto' }} />
                                ) : (
                                    <label className="img-thumbnail mt-3" style={{ width: '100%', height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                        <span style={{ color: '#6c757d' }}>
                                            <img src='/imgs/body.png' className='img-responsive about-img' alt='Body' style={{ maxWidth: '30%', filter: 'grayscale(100%)' }}  />
                                            <input type="file" className="form-control" style={{ display: 'none' }} onChange={(e) => handleImageChange(e, setTargetImageFile, setTargetImagePreview)} />
                                        </span>
                                    </label>
                                )}
                            </Col>
                        </Row>
                        {/* Gender Selection with Tooltip */}
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props) => renderTooltip(props, "Select the gender for the face swap.")}
                        >
                            <div className="mb-3 text-center">
                                <div className="form-check d-inline-block">
                                <input
                                    className="form-check-input d-inline-block ms-3"
                                    type="radio"
                                    name="genderOptions"
                                    id="genderFeminine"
                                    value="Feminine"
                                    checked={gender === 'Feminine'}
                                    onChange={(e) => setGender(e.target.value)}
                                />
                                <label className="form-check-label" htmlFor="genderFeminine">
                                    Feminine
                                </label>
                            </div>
                            <div className="form-check d-inline-block">
                            <input
                                className="form-check-input d-inline-block ms-3"
                                type="radio"
                                name="genderOptions"
                                id="genderMasculine"
                                value="Masculine"
                                checked={gender === 'Masculine'}
                                onChange={(e) => setGender(e.target.value)}
                            />
                            <label className="form-check-label" htmlFor="genderMasculine">
                                Masculine
                            </label>
                            </div>
                            </div>
                        </OverlayTrigger>
                        {true && (
                            <div className="text-center">
                                <Button onClick={startFaceSwapProcessing}>Start Face Swap</Button>
                            </div>
                        )}
                    </Col>
                        
                    <Col md={6}>
                        {outputImage ? (
                            <div>
                                <Row>
                                    <Col md={9}>
                                        <ReactCompareImage leftImage={targetImagePreview} rightImage={outputImage} />
                                    </Col>
                                    <Col md={3}>
                                        <div style={{ maxWidth: '100px', height: 'auto' }}>
                                            <DisplayImage src={outputImage} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <div className="img-thumbnail mt-3" style={{ width: '100%', height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: '#6c757d' }}>No image to display</span>
                            </div>
                        )}
                        {/* Feedback Buttons */}
                        {outputImage && (
                            <div className="feedback-buttons mt-3 d-flex justify-content-center">
                                <Row>
                                    <Col>
                                    <FontAwesomeIcon 
                                        icon={faThumbsUp} 
                                        className="text-success mr-2 icon-animate" 
                                        style={{ cursor: 'pointer', fontSize: '2rem' }} 
                                        onClick={() => handleLikeDislike(true)} 
                                    />
                                    </Col>
                                    <Col>
                                    <FontAwesomeIcon 
                                        icon={faThumbsDown} 
                                        className="text-danger ml-2 icon-animate" 
                                        style={{ cursor: 'pointer', fontSize: '2rem' }} 
                                        onClick={() => handleLikeDislike(false)} 
                                    />
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Col>
                </Row>

                {/* Feedback Modal */}
                <Modal show={showFeedbackModal} onHide={() => setShowFeedbackModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea
                            className="form-control"
                            rows="3"
                            placeholder="Your feedback... (Optional)"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                        ></textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowFeedbackModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => recordFeedback(false, feedback)}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Modal for Messages */}
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Notification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

export default FaceSwapUploader;
