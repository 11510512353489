import { useContext, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { AccountContext } from './Account';
import { Button, Form, Container, Row, Col, Modal } from 'react-bootstrap';
import SEO from './SEO';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const navigate = useNavigate();

    const { authenticate } = useContext(AccountContext);

    const handleClose = () => setShowModal(false);
    const handleShow = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        authenticate(username, password)
        .then((data) => {
            //console.log(data);
            handleShow('Login successful');
            window.location.href = '/';  // Redirect to home or other page
        })
        .catch((err) => {
            //console.log(err);
            handleShow('Login failure : ' + err);
        });
    };

    return (
        <div>
            <SEO title="Login | GetMyst"
            description="Login to access your GetMyst.app account. Enter a world of creative photo editing tools and features."
            name="GetMyst.app"
            type="website" 
            url="https://getmyst.app/login"
            />
            <Container className="mt-5">
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                    <h1 className="text-center mb-4">Login</h1>
                        <Form onSubmit={onSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter email"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">Login</Button>
                        </Form>
                        <div className="text-center mt-3">
                            <Link to="/forgot-password" className="forgot-password-link">Forgot Password?</Link>
                        </div>
                        <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Message</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{modalMessage}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
                
            </Container>
        </div>
    );
}

export default Login;