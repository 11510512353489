import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <div className="hero-container">
      <h2>AI-Powered Image Transformations</h2>
      <h3>Transform faces into anime characters, swap faces between photos, or try on different outfits virtually - all powered by advanced AI technology. Experience creative transformations instantly!</h3>
    </div>
  );
}

export default Hero;
