import React, { useContext, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { AccountContext } from './Account';
import UserPool from './UserPool';

const SuccessPayment = () => {
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        // Force refresh the cognito session
        const user = UserPool.getCurrentUser();
        user.getSession(function (err, session) {
          console.log(user)
          user.refreshSession(user.getSignInUserSession().getRefreshToken(), (err, session) => {
            if (err) {
              console.error('Error refreshing session:', err);
            } else {
              // After refresh, get the new session
              getSession()
                .then(session => {
                  console.log('Session refreshed successfully');
                })
                .catch(err => {
                  console.error('Error getting new session:', err);
                });
            }
          });
        });
    }, []);

  const handleHomeRedirect = () => {
    window.location.href = '/';  // Reloads the home page
  };

  return (
    <Container className="text-center mt-4">
      <FontAwesomeIcon 
        icon={faCircleCheck} 
        style={{ color: 'green', fontSize: '100px' }} 
      />
      <h2 className="my-4">Payment Successful!</h2>
      <Button variant="primary" onClick={handleHomeRedirect}>
        Home
      </Button>
    </Container>
  );
};

export default SuccessPayment;
