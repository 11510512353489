import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic, faRocket, faMobileAlt, faBrain } from '@fortawesome/free-solid-svg-icons';

const FeaturesSection = () => (
    <div id='features' className='text-center'>
    <div className='container'>
      <div className='col-md-offset-1'>
        <h2>Our Features</h2>
      </div>
      <div className='row'>
        <div className='col-xs-6 col-md-3'>
          <FontAwesomeIcon icon={faMagic} size="3x" />
          <h3>AI Transformation</h3>
          <p>Experience the magic of AI as it transforms your images into your desired theme or character.</p>
        </div>
        <div className='col-xs-6 col-md-3'>
          <FontAwesomeIcon icon={faRocket} size="3x" />
          <h3>Fast Processing</h3>
          <p>Quick and efficient image processing that delivers your transformed image in no time.</p>
        </div>
        <div className='col-xs-6 col-md-3'>
          <FontAwesomeIcon icon={faMobileAlt} size="3x" />
          <h3>Mobile Friendly</h3>
          <p>Access our platform on-the-go with a responsive design that adapts to all devices.</p>
        </div>
        <div className='col-xs-6 col-md-3'>
          <FontAwesomeIcon icon={faBrain} size="3x" />
          <h3>Intuitive Design</h3>
          <p>User-friendly interface ensuring a seamless experience, making it easy for everyone.</p>
        </div>
      </div>
    </div>
  </div>
);

export default FeaturesSection;
