import React from "react";
import { initiateSubscription } from './api';

const styles = {
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#242d60',
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Ubuntu', sans-serif",
    height: '100vh',
    margin: 0,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%'
  },
  section: {
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    height: '112px',
    borderRadius: '6px',
    justifyContent: 'space-between',
  },
  product: {
    display: 'flex',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paragraph: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.154px',
    color: '#242d60',
    height: '100%',
    width: '100%',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  image: {
    borderRadius: '6px',
    margin: '10px',
    width: '54px',
    height: '57px',
    objectFit: 'contain'
  },
  heading: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.154px',
    color: '#242d60',
    margin: 0,
  },
  price: {
    opacity: 0.5,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.154px',
    color: '#242d60',
    margin: 0,
  },
  button: {
    height: '36px',
    background: '#556cd6',
    color: 'white',
    width: '100%',
    fontSize: '14px',
    border: 0,
    fontWeight: 500,
    cursor: 'pointer',
    letterSpacing: 0.6,
    borderRadius: '0 0 6px 6px',
    transition: 'all 0.2s ease',
    boxShadow: '0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)',
    '&:hover': {
      opacity: 0.8,
    },
  },
};

const SubscriptionProcess = () => {
    const handleSubscribe = async () => {
        try {
            const data = await initiateSubscription();
        if (data.url) {
            window.location.href = data.url;
        } else {
            throw new Error('URL not found in response');
        }
    } catch (error) {
        console.error("Error during subscription:", error);
    }
};

  return (
    <div style={styles.container}>
      <section style={styles.section}>
        <div style={styles.product}>
          <img
            style={styles.image}
            src="/logo512.png"
            alt="Logo"
          />
          <div style={styles.description}>
            <h3 style={styles.heading}>Monthly Subscription</h3>
            <h5 style={styles.price}>$4.99</h5>
          </div>
        </div>
        <button 
                    style={styles.button} 
          onClick={handleSubscribe}
        >
          Checkout
        </button>
      </section>
    </div>
  );
};

export default SubscriptionProcess;
