const TRIAL_LIMIT = 5;

const useTrial = () => {
    const getTrialCount = () => {
        return parseInt(localStorage.getItem('trialCount') || '0', 10);
    };

    const incrementTrialCount = () => {
        const currentCount = getTrialCount();
        localStorage.setItem('trialCount', currentCount + 1);
    };

    const isTrialExpired = () => {
        return getTrialCount() >= TRIAL_LIMIT;
    };

    return { incrementTrialCount, isTrialExpired };
};

export default useTrial;
