import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import SEO from './SEO';

const blogPosts = {
  "beginners-guide-to-stable-diffusion": {
    title: "A Beginner's Guide to Stable Diffusion",
    content: "# Understanding Stable Diffusion\n\nStable Diffusion is a powerful AI image generation model that has revolutionized the way we create digital art. This deep learning model can generate highly detailed images from text descriptions, modify existing images, and even inpaint specific areas of photos.\n\nIn this guide, we'll explore the basics of how Stable Diffusion works, how to get started using it, and some best practices for generating amazing results.",
    date: "2023-12-21"
  },
  "mastering-ip-adapters": {
    title: "Mastering IP Adapters in AI Image Generation", 
    content: "# IP Adapters Explained\n\nIP Adapters (Image Prompt Adapters) represent a significant advancement in AI image generation, allowing for more precise control over the output by using reference images alongside text prompts.\n\nThis technology enables you to maintain consistent styles, characters, or objects across multiple generations while still having the flexibility to modify other aspects of the image. Learn how to effectively use IP Adapters to enhance your AI image generation workflow.",
    date: "2023-12-22"
  },
  "stable-diffusion-vs-flux": {
    title: "Stable Diffusion vs Flux: A Comprehensive Comparison",
    content: "# Comparing AI Image Models\n\nIn the rapidly evolving landscape of AI image generation, two notable frameworks have emerged: Stable Diffusion and Flux. While both aim to create high-quality images from text prompts, they differ significantly in their approaches, capabilities, and use cases.\n\nThis article provides a detailed comparison of these two technologies, helping you understand which tool might be better suited for your specific needs.",
    date: "2023-12-23"
  }
};

function BlogPost() {
  const { slug } = useParams();
  const post = blogPosts[slug];

  if (!post) {
    return <Container className="py-5"><h1>Post not found</h1></Container>;
  }

  return (
    <>
      <SEO
        title={`${post.title} | GetMyst`}
        description={post.content.substring(0, 155)}
        name="GetMyst.app"
        type="article"
      />
    <Container className="py-5">
      <article>
        <h1>{post.title}</h1>
        <div className="text-muted mb-4">
          {new Date(post.date).toLocaleDateString()}
        </div>
        <ReactMarkdown>
          {post.content}
        </ReactMarkdown>
      </article>
      </Container>
    </>
  );
}

export default BlogPost;