import React from 'react';
import { Helmet } from 'react-helmet-async';
 
export default function SEO({ 
    title, 
    description, 
    name, 
    type,
    image, // Add image support
    url // Add canonical URL support
  }) {
    const siteUrl = "https://getmyst.app"
    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            <link rel="canonical" href={url || siteUrl} />
            
            {/* Open Graph / Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:site_name" content="GetMyst" />
            <meta property="og:url" content={url || siteUrl} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {/*image && <meta property="og:image" content={image} />*/}
            
            {/* Twitter tags */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={image ? "summary_large_image" : "summary"} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {/*image && <meta name="twitter:image" content={image} />*/}
            { /* End Twitter tags */ }
        </Helmet>
    )
}