import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import HeaderSection from './HomeHeaderSection';
import FeaturesSection from './HomeFeaturesSection';
import AboutSection from './HomeAboutSection';
import ImageUploader from './components/ImageUploader';
import SocialIcons from './HomeSocialIcons';
import HomePricingSection from './HomePricingSection';
import Hero from './Hero';
import SEO from './SEO';

function HomePage() {
  const imageUploaderRef = useRef(null);
  const examplesRef = useRef(null);
  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);

  const scrollToImageUploader = () => imageUploaderRef.current.scrollIntoView({ behavior: 'smooth' });
  const scrollToExamples = () => examplesRef.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <>
      <SEO 
        title="AI Image Transformations | GetMyst"
        description="Transform faces into anime characters, swap faces between photos, or try on different outfits virtually - all powered by advanced AI technology. Experience creative transformations instantly!"
        name="GetMyst.app"
        type="website"
        url="https://getmyst.app"
      />
      <HeaderSection scrollToImageUploader={scrollToImageUploader} scrollToExamples={scrollToExamples} />
      <div data-aos="fade-in">
        <Hero />
      </div>
      <div data-aos="fade-in">
        <div ref={imageUploaderRef} id="imageUploaderSection">
          <ImageUploader />
        </div>
      </div>
      <div data-aos="fade-down">
        <FeaturesSection />
      </div>
      <div data-aos="fade-down">
        <div ref={examplesRef} id="examplesSection">
          <AboutSection />
        </div>
      </div>
      <div data-aos="fade-up">
        <div id="pricingSection">
          <HomePricingSection />
        </div> 
      </div>
      <SocialIcons />
    </>
  );
}

export default HomePage;
