import { useState } from 'react';
import React from 'react';
import { Form, Button, Container, Modal } from 'react-bootstrap';
import SEO from './SEO';

const ContactPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleClose = () => setShowModal(false);
    const handleShow = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Collect form data
        const formData = {
            name: event.target.elements.name.value,
            email: event.target.elements.email.value,
            message: event.target.elements.message.value,
        };

        // Send POST request to your API endpoint
        try {
            const response = await fetch('https://n1qqit84dj.execute-api.us-east-1.amazonaws.com/prod/contact-us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                handleShow("Email sent successfully");
            } else {
                handleShow("Failed to send email");
            }
        } catch (error) {
            handleShow("An error occurred while sending the email.");
        }
    };

    return (
        <div>
            <SEO title="Contact Us | GetMyst"
            description="Have questions or feedback? Get in touch with the GetMyst.app team today. We're here to help you with any inquiries."
            name="GetMyst.app"
            type="website" 
            url="https://getmyst.app/contact"
            />
            <Container className="mt-5">
                <div className="contact-page">
                    <h1>Contact Us</h1>
                    <Form className="contact-form" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your name" name="name" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" name="email" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Your message" name="message" />
                        </Form.Group>

                        <Button variant="primary" type="submit">Submit</Button>
                    </Form>
                </div>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
        
    );
};

export default ContactPage;
