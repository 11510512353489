import React from 'react';
import FaceSwapUploader from './components/FaceSwapUploader';
import SEO from './SEO';

function FaceSwapPage() {
  return (
    <div>
      <SEO 
        title="Face Swap using AI | GetMyst"
        description="Discover the fun of face swapping! Instantly exchange faces in photos for laughs and surprises using AI. Perfect for sharing with friends. Try GetMyst.app's Face Swap now!"
        name="GetMyst.app"
        type="website"
        url="https://getmyst.app/swap"
      />

      <div className="container mt-5">
        <h1 className="text-center mb-4">AI Face Swap</h1>
        <p className="text-center mb-5">
          Upload two photos and watch as our AI seamlessly swaps the faces between them.
          Great for creating fun photo edits and sharing with friends!
        </p>

        <FaceSwapUploader />
      </div>
    </div>
  );
}

export default FaceSwapPage;