import React from "react";

const TermsOfService = () => {
  const developerName = "Get Myst OU";
  const date = "July 21, 2024"; // Update this dynamically as per requirement
  const appName = "GetMyst";
  const supportEmail = "contact@getmyst.app";
  const privacyPolicyLink = "https://getmyst.app/privacy";
  const timePeriod = "30 days"; // Example period, change accordingly

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>{developerName} Terms of Service</h1>
      <p>Last Updated: <strong>{date}</strong></p>

      <p>
        These terms of service ("Terms") apply to your access and use of <strong>{appName}</strong> 
        (the "Service"). Please read them carefully.
      </p>

      <h2>Accepting these Terms</h2>
      <p>
        If you access or use the Service, it means you agree to be bound by all of the terms below.
        So, before you use the Service, please read all of the terms. If you don't agree to all of
        the terms below, please do not use the Service. Also, if a term does not make sense to you,
        please let us know by e-mailing <strong>{supportEmail}</strong>.
      </p>

      <h2>Changes to these Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. For instance, we may need to change 
        these Terms if we come out with a new feature or for some other reason. 
      </p>
      <p>
        Whenever we make changes to these Terms, the changes are effective <strong>{timePeriod}</strong> after we 
        post such revised Terms (indicated by revising the date at the top of these Terms) or upon 
        your acceptance if we provide a mechanism for your immediate acceptance of the revised Terms 
        (such as a click-through confirmation or acceptance button). It is your responsibility to 
        check <strong>{appName}</strong> for changes to these Terms. 
      </p>
      <p>
        If you continue to use the Service after the revised Terms go into effect, then you have 
        accepted the changes to these Terms.
      </p>

      <h2>Privacy Policy</h2>
      <p>
        For information about how we collect and use information about users of the Service, please 
        check out our privacy policy available at <a href={privacyPolicyLink}>{privacyPolicyLink}</a>.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        From time to time, we may provide you with links to third party websites or services that we 
        do not own or control. Your use of the Service may also include the use of applications that 
        are developed or owned by a third party. Your use of such third party applications, websites, 
        and services is governed by that party's own terms of service or privacy policies. We encourage 
        you to read the terms and conditions and privacy policy of any third party application, website 
        or service that you visit or use.
      </p>

      <h2>Creating Accounts</h2>
      <p>
        When you create an account or use another service to log in to the Service, you agree to maintain 
        the security of your password and accept all risks of unauthorized access to any data or other 
        information you provide to the Service. If you discover or suspect any Service security breaches, 
        please let us know as soon as possible.
      </p>

      <h2>Your Content & Conduct</h2>
      <p>
        Our Service allows you and other users to post, link and otherwise make available content. You are 
        responsible for the content that you make available to the Service, including its legality, reliability, 
        and appropriateness.
      </p>

      <p>
        When you post, link or otherwise make available content to the Service, you grant us the right and license 
        to use, reproduce, modify, publicly perform, publicly display and distribute your content on or through 
        the Service. We may format your content for display throughout the Service, but we will not edit or revise 
        the substance of your content itself.
      </p>

      <p>
        Aside from our limited right to your content, you retain all of your rights to the content you post, link 
        and otherwise make available on or through the Service. You can remove the content that you posted by 
        deleting it. Once you delete your content, it will not appear on the Service, but copies of your deleted 
        content may remain in our system or backups for some period of time.
      </p>

      {/* Continue to add remaining sections as needed */}
    </div>
  );
};

export default TermsOfService;