import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SEO from './SEO';
const blogPosts = [
  {
    id: 1,
    title: "A Beginner's Guide to Stable Diffusion",
    excerpt: "Stable Diffusion is a powerful AI image generation model that has revolutionized the way we create digital art. This deep learning model can generate highly detailed images from text descriptions, modify existing images, and even inpaint specific areas of photos.",
    date: "2023-12-21",
    slug: "beginners-guide-to-stable-diffusion"
  },
  {
    id: 2, 
    title: "Mastering IP Adapters in AI Image Generation",
    excerpt: "IP Adapters (Image Prompt Adapters) represent a significant advancement in AI image generation, allowing for more precise control over the output by using reference images alongside text prompts.",
    date: "2023-12-22",
    slug: "mastering-ip-adapters"
  },
  {
    id: 3,
    title: "Stable Diffusion vs Flux: A Comprehensive Comparison", 
    excerpt: "In the rapidly evolving landscape of AI image generation, two notable frameworks have emerged: Stable Diffusion and Flux. While both aim to create high-quality images from text prompts, they differ significantly in their approaches, capabilities, and use cases.",
    date: "2023-12-23",
    slug: "stable-diffusion-vs-flux"
  }
];

function BlogPage() {
  return (
    <>
      <SEO
        title="Blog | GetMyst"
        description="Read our latest articles about AI image transformation, face swapping technology, and digital art creation techniques."
        name="GetMyst.app"
        type="website"
      />
      
    <Container className="py-5">
      <h1 className="mb-4">Blog</h1>
      <Row>
        {blogPosts.map(post => (
          <Col md={4} key={post.id} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.excerpt}</Card.Text>
                <Link to={`/blog/${post.slug}`} className="btn btn-primary">
                  Read More
                </Link>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  {new Date(post.date).toLocaleDateString()}
                </small>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </>
  );
}

export default BlogPage;