import React from 'react';
import { Button } from 'react-bootstrap';
import ParticlesBg from "particles-bg";

const HeaderSection = ({ scrollToImageUploader, scrollToExamples }) => (
  <div style={{ position: 'relative', height: '300px', color: 'white' }}>
    <ParticlesBg type="circle" bg={{ zIndex: 0, position:"absolute", top:0, width: '100%', height: '100%' }} />
    <div style={{ zIndex: 1, position: 'absolute', textAlign: 'center', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <h1>Welcome to GetMyst</h1>
      <p>Transform your image into anything you can imagine</p>
      <Button variant="primary" onClick={scrollToImageUploader}>Try Now</Button>
      <Button variant="secondary" onClick={scrollToExamples}>Examples</Button>
    </div>
  </div>
);

export default HeaderSection;
