import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaPenSquare, FaTwitter, 
    FaInstagram, FaLinkedin, FaEnvelope, FaSuitcaseRolling } from 'react-icons/fa';
import { Row } from 'react-bootstrap';
const SocialIcons = () => (
    <footer className="footer">
            <div className="container">
                <div className="footer-flex">
                    <div className="footer-contact">
                        <h3>GetMyst</h3>
                        <Row>
                        <Link to="/contact" className="footer-contact-link"><FaEnvelope /> Contact Us</Link>
                        </Row>
                        <Row>
                        <Link to="/privacy" className="footer-contact-link"><FaPenSquare /> Privacy Policy</Link>
                        </Row>
                        <Row>
                        <Link to="/tos" className="footer-contact-link"><FaSuitcaseRolling /> Terms and Conditions</Link>
                        </Row>
                    </div>
                    <div className="footer-social">
                        <h3>Follow Us</h3>
                        <div className="social-links">
                            <a href="https://www.facebook.com/getmystapp" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                            <a href="#" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                            <a href="https://www.instagram.com/getmyst.app" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                            <a href="#" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                        </div>
                    </div>
                </div>
            </div>
    </footer>
);

export default SocialIcons;
