import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AccountContext } from './Account';
import { Container, Row, Col, Button, Image, Spinner, Form, Modal } from 'react-bootstrap';

const Profile = () => {
    const [data, setData] = useState(null); 
    const [nextLastEvaluatedKey, setNextLastEvaluatedKey] = useState(null);
    const [prevLastEvaluatedKey, setPrevLastEvaluatedKey] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { getSession, getToken, logout } = useContext(AccountContext);
    const [userInfo, setUserInfo] = useState({ email: '', name: '' }); // Placeholder state for user info
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigate = useNavigate();
    // Function to handle account deletion
    const handleDeleteAccount = async () => {
        try {
            const token = await getToken();
            await axios.delete('https://n1qqit84dj.execute-api.us-east-1.amazonaws.com/prod/delete-account', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // Log out the user and redirect them, or handle as appropriate
            logout();
            navigate('/');
        } catch (error) {
            console.error('Error deleting account', error);
        }
    };
    

    useEffect(() => {
        getSession()
            .then(() => {
                fetchData();
            })
            .catch(() => {
                navigate('/login');
            });
    }, []);

    // Handler for profile form (placeholder function)
    const handleProfileFormSubmit = (event) => {
        event.preventDefault();
        // Logic to update user profile info
    };

    const fetchData = async (lastKey, isNext = true) => {
        setIsLoading(true);
        try {
            const token = await getToken();
            let url = `https://n1qqit84dj.execute-api.us-east-1.amazonaws.com/prod/query-profile-finished`;

            if (lastKey) {
                const lastKeyString = encodeURIComponent(JSON.stringify(lastKey));
                url += `?lastEvaluatedKey=${lastKeyString}`;
            } else {
                url += `?lastEvaluatedKey=`;
            }

            axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                setData(response.data.items[0]);
                setNextLastEvaluatedKey(response.data.lastEvaluatedKey);

                if (isNext) {
                    setPrevLastEvaluatedKey(prevKeys => [...prevKeys, lastKey]);
                } else {
                    setPrevLastEvaluatedKey(prevKeys => prevKeys.slice(0, -1));
                }

                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data', error);
                setIsLoading(false);
            });
        } catch (error) {
            console.error('Error fetching data', error);
            setIsLoading(false);
        }
    };

    const handleNext = () => {
        fetchData(nextLastEvaluatedKey);
    };

    const handlePrevious = () => {
        // Get the second last key as the last one is the current page
        const prevKey = prevLastEvaluatedKey[prevLastEvaluatedKey.length - 2];
        fetchData(prevKey, false);
    };

    return (
        <Container className="mt-4">
            <h2 className="mb-4">Profile</h2>
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleProfileFormSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={userInfo.email}
                                onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter name"
                                value={userInfo.name}
                                onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Update Profile
                        </Button>
                    </Form>

                    <Button 
                      variant="success" 
                      className="mt-3"
                      onClick={() => window.location.href = '/subscription-1'}
                    >
                      Purchase Subscription
                    </Button>

                    {/* Delete Account Button */}
                    <Button variant="danger" className="mt-3" onClick={() => setShowDeleteModal(true)}>
                        Delete Account
                    </Button>

                    {/* Delete Confirmation Modal */}
                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Account Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete your account? This action is irreversible.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleDeleteAccount}>
                                Delete Account
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>

                <Col md={6}>
                    {isLoading ? (
                        <div className="text-center">
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <div>
                            {data && (
                                <Row>
                                    <Col md={6}>
                                        <h5>Before Image</h5>
                                        <Image src={data.input_file_url} thumbnail fluid />
                                    </Col>
                                    <Col md={6}>
                                        <h5>After Image</h5>
                                        <Image src={data.output_file_url} thumbnail fluid />
                                    </Col>
                                </Row>
                            )}
                            <div className="mt-4">
                                {prevLastEvaluatedKey.length > 1 && (
                                    <Button variant="secondary" onClick={handlePrevious} className="me-2">Previous</Button>
                                )}
                                {nextLastEvaluatedKey && (
                                    <Button variant="primary" onClick={handleNext}>Next</Button>
                                )}
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
